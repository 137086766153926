import getSeoFriendlyCategoryName from './getSeoFriendlyCategoryName';
import type { Categories, CategoryObject } from '../types';

export type CategoryExtended = {
  // SEO friendly category name is the category name that we send in the url. We often use this as a param for third party tracking
  sfc: CategoryObject;
} & Categories;

const getCategoryProperty = ({ categories }: { categories?: Categories }): CategoryExtended => {
  const defaultCategories = { l1: { id: -1 }, l2: { id: -1 }, c: { id: -1 } };
  const result: Categories = { ...defaultCategories, ...categories };
  const sfc = getSeoFriendlyCategoryName(result.l1);

  return { ...result, sfc };
};

export default getCategoryProperty;
