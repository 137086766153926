import optimizelySDK, { Client } from '@optimizely/optimizely-sdk';
import { getHoustonOptimizelySdkOptions } from '@houston/js-event-dispatcher';
import { Logger, THoustonConfig } from '../types/THoustonConfig';
import { getTenantName } from '../utils/createHoustonConfig';

type CreateOptimizelyManagerProps = {
  logger?: Logger;
  instanceOptions: {
    eventFlushInterval: number;
    eventBatchSize: number;
  };
};

type CreateOptimizelyClientProps = {
  houstonConfig: THoustonConfig;
  enableLogger?: boolean;
};

const createOptimizelyManager = ({ logger, instanceOptions }: CreateOptimizelyManagerProps) => {
  const instances: Record<string, Client | null> = {};
  const { eventFlushInterval, eventBatchSize } = instanceOptions;

  async function createOptimizelyClient({ houstonConfig, enableLogger = false }: CreateOptimizelyClientProps) {
    const tenantName = houstonConfig.tenant;
    if (!instances[tenantName]) {
      const { sdkKey, eventDispatcher, datafileOptions } = await getHoustonOptimizelySdkOptions(houstonConfig);

      instances[tenantName] = optimizelySDK.createInstance({
        sdkKey,
        eventDispatcher,
        errorHandler: {
          handleError: (error) => {
            logger?.error(`optimizely error ${error}`);
          },
        },
        logger: {
          log: (level, message) => {
            if (enableLogger && process.env.NODE_ENV !== 'production') {
              logger?.info(`optimizely log ${level} ${message}`);
            }
          },
        },
        datafileOptions: {
          ...datafileOptions,
          updateInterval: 5000,
        },
        eventFlushInterval,
        eventBatchSize,
      });

      try {
        await instances[tenantName]?.onReady();
      } catch (error) {
        logger?.error(`optimizely error ${error}`);
      }
    }

    return instances[tenantName] as Client;
  }

  /**
   * Retrieves the Optimizely client instance for a specific tenant.
   * Returns undefined if the tenant is considered a bot.
   */
  function getOptimizelyClient(tenantKey: string) {
    const tenantName = getTenantName(tenantKey);
    return instances[tenantName] as Client;
  }

  return { createOptimizelyClient, getOptimizelyClient };
};

export default createOptimizelyManager;
