import type { UserAttributes } from '@optimizely/optimizely-sdk';

import type { getOptimizelyClient as TGetClient } from '../client/browser';

const activateExperiment =
  (getOptimizelyClient: typeof TGetClient) =>
  ({
    experimentKey,
    experimentUser,
    attributes,
    tenantKey,
  }: {
    experimentKey: string;
    experimentUser: string | number | null;
    attributes?: UserAttributes;
    tenantKey: string;
  }): string | null => {
    const optimizelyClient = getOptimizelyClient(tenantKey);

    if (!optimizelyClient || !tenantKey || !experimentUser) {
      return null;
    }

    return optimizelyClient?.activate(experimentKey, `${experimentUser}`, attributes);
  };

export default activateExperiment;
