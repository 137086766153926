import type { UserAttributes } from '@optimizely/optimizely-sdk';
import type { getOptimizelyClient as TGetClient } from '../client/browser';

const isExperimentEnabled =
  (getOptimizelyClient: typeof TGetClient) =>
  ({
    experimentKey,
    experimentUser,
    variation,
    tenantKey,
    attributes,
  }: {
    experimentKey: string;
    experimentUser: string | number | null;
    variation?: string;
    tenantKey: string;
    attributes?: UserAttributes;
  }): boolean => {
    const optimizelyClient = getOptimizelyClient(tenantKey);
    if (!optimizelyClient || !tenantKey || !experimentUser) {
      return false;
    }
    return variation === optimizelyClient.activate(experimentKey, `${experimentUser}`, attributes);
  };

export default isExperimentEnabled;
