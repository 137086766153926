import { format as dateFormat } from 'date-fns';
import type { Request } from 'express';
import getCategoryProperty from './getCategoryProperty';
import type { LoggedInUser, AdDetails, Categories, OrderDetails, ABSwitches } from '../types';
import hash from '../hash';

const encodeABSwitches = (config: ABSwitches) =>
  Object.keys(config)
    .map((name) => `${name}_${config[name].group}`.toLowerCase())
    .join('|');

// all documentation for these properties can be found here -> https://ecgwiki.corp.ebay.com/pages/viewpage.action?spaceKey=ANYL&title=Data+Layer

class DataLayerBuilder {
  // must initialise with empty object
  _dataLayer: { [key: string]: any }[] = [{}];

  constructor() {
    this.withSearchDefaults().withLocationDefaults().withMessageDefaults();
  }

  withSearchDefaults() {
    this._dataLayer[0].s = {
      pn: -1,
      ps: -1,
      tr: -1,
      srt: {},
      prc: {
        mn: -1,
        mx: -1,
      },
    };
    return this;
  }

  withLocationDefaults() {
    this._dataLayer[0].l = {
      d: -1.0,
    };
    return this;
  }

  withMessageDefaults() {
    this._dataLayer[0].m = {};
    return this;
  }

  withPageTypeAndLang(pageType: string, acceptedLanguage: string) {
    this._dataLayer[0].p = {
      t: pageType,
      lng: acceptedLanguage,
    };
    return this;
  }

  withCategoryData(categories?: Categories) {
    this._dataLayer[0].c = getCategoryProperty({ categories });
    return this;
  }

  withLoggedInUserInfo(loggedInUser?: LoggedInUser) {
    this._dataLayer[0].u = {
      ...this._dataLayer[0].u,
      li: Boolean(loggedInUser),
    };

    if (!loggedInUser) return this;

    this._dataLayer[0].u = {
      ...this._dataLayer[0].u,
      at: loggedInUser.accountType,
      a_cre: dateFormat(loggedInUser.confirmDate, 'YYYYMMDD'),
      huid: loggedInUser.encryptedId,
      hue: loggedInUser.hashedEmail,
      lar: loggedInUser.lastAdRepliedTime,
      lap: loggedInUser.lastAdPostedTime,
    };
    return this;
  }

  withAbSwitchesInfo(abSwitches?: ABSwitches) {
    if (!abSwitches) return this;
    this._dataLayer[0].u = {
      ...this._dataLayer[0].u,
      tg: {
        stg: encodeABSwitches(abSwitches),
      },
    };
    return this;
  }

  withSellerBehaviour(sellerBehaviour?: string) {
    if (!sellerBehaviour) return this;
    this._dataLayer[0].u = {
      ...this._dataLayer[0].u,
      ss: sellerBehaviour,
    };
    return this;
  }

  withCookiesAndUserAgents(req: Request) {
    const userPermanentCookie = '__mpx';
    const sessionCookie = 'MpSession';

    this._dataLayer[0].d = {
      ck: hash(req.cookies[userPermanentCookie]),
      s_ck: req.cookies[sessionCookie],
      ua: req.get('user-agent'),
    };
    return this;
  }

  withAdDetails(adDetails: AdDetails) {
    this._dataLayer[0].a = adDetails;
    return this;
  }

  withOrderDetails(orderDetails: OrderDetails) {
    this._dataLayer[0].o = orderDetails;
    return this;
  }

  withConsentToAllPurposes(consentedToAllPurposes: boolean) {
    this._dataLayer.push({
      consentToAllPurposes: consentedToAllPurposes,
    });
    return this;
  }

  build() {
    return this._dataLayer;
  }
}

export default DataLayerBuilder;
