import type { getOptimizelyClient as TGetClient } from '../client/browser';
import type { UserAttributes, EventTags } from '@optimizely/optimizely-sdk';

const trackExperiment =
  (getOptimizelyClient: typeof TGetClient) =>
  ({
    eventKey,
    experimentUser,
    attributes,
    eventTags,
    tenantKey,
  }: {
    eventKey: string;
    experimentUser: string | number | null;
    attributes?: UserAttributes;
    eventTags?: EventTags;
    tenantKey: string;
  }): void | null => {
    const optimizelyClient = getOptimizelyClient(tenantKey);
    if (!optimizelyClient || !tenantKey || !experimentUser) {
      return null;
    }

    return optimizelyClient?.track(eventKey, `${experimentUser}`, attributes, eventTags);
  };

export default trackExperiment;
