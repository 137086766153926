import type { Request } from 'express';
import DataLayerBuilder from './utils/DataLayerBuilder';
import type { LoggedInUser, LabsConfig, Categories, AdDetails, OrderDetails } from './types';

export type Params = {
  req: Request;
  pageType: string;
  sellerBehaviour?: string;
  loggedInUser?: LoggedInUser;
  labsConfig?: LabsConfig;
  categories?: Categories;
  adDetails?: AdDetails;
  orderDetails?: OrderDetails;
  consentedToAllPurposes?: boolean;
};

/**
 * Creates dataLayer object
 */
const createDataLayerObject = ({
  req,
  pageType,
  sellerBehaviour,
  loggedInUser,
  labsConfig = {},
  categories,
  adDetails = {},
  orderDetails = {},
  consentedToAllPurposes = false,
}: Params) =>
  new DataLayerBuilder()
    .withPageTypeAndLang(pageType, req.get('accept-language')!)
    .withCategoryData(categories)
    .withLoggedInUserInfo(loggedInUser)
    .withAbSwitchesInfo(labsConfig.abSwitches)
    .withSellerBehaviour(sellerBehaviour)
    .withCookiesAndUserAgents(req)
    .withAdDetails(adDetails)
    .withOrderDetails(orderDetails)
    .withConsentToAllPurposes(consentedToAllPurposes)
    .build();

export default createDataLayerObject;
