// env: browser
import { createOptimizelyClient, getOptimizelyClient } from './client/browser';
import isExperimentEnabledWrapper from './helpers/isExperimentEnabled';
import isFeatureEnabledWrapper from './helpers/isFeatureEnabled';
import activateExperimentWrapper from './helpers/activateExperiment';
import trackExperimentWrapper from './helpers/trackExperiment';

export const isFeatureEnabled = isFeatureEnabledWrapper(getOptimizelyClient);
export const isExperimentEnabled = isExperimentEnabledWrapper(getOptimizelyClient);
export const activateExperiment = activateExperimentWrapper(getOptimizelyClient);
export const trackExperiment = trackExperimentWrapper(getOptimizelyClient);

export { createOptimizelyClient, getOptimizelyClient };
