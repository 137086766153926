// env: node
import { THoustonConfig, THoustonClientConfig, THoustonServerConfig } from '../types/THoustonConfig';

export type TCreateHoustonConfigProps = {
  tenant: string;
  environment: 'pre' | 'pro';
  username: string;
  password: string;
};

const MP_PROVIDER_ID = 'sdrn:schibsted:client:marktplaats';
const MP_USER_ID_PREFIX = 'sdrn:marktplaats:user:';

const TWH_PROVIDER_ID = 'sdrn:schibsted:client:2dehands';
const TWH_USER_ID_PREFIX = 'sdrn:2dehands:user:';

const tenantApplicationMap = {
  mp: 'marktplaats-web',
  twh: '2dehands-web',
};

const tenantNameMap = {
  mp: 'marktplaats',
  twh: '2dehands',
};

const tenantProviderIDMap = {
  mp: MP_PROVIDER_ID,
  twh: TWH_PROVIDER_ID,
};

const tenantexperimentUserPrefixMap = {
  mp: MP_USER_ID_PREFIX,
  twh: TWH_USER_ID_PREFIX,
};

function getApplicationId(tenant: string) {
  return tenantApplicationMap[tenant] || null;
}

export function getTenantName(tenant: string) {
  return tenantNameMap[tenant] || null;
}

function getproviderId(tenant: string) {
  return tenantProviderIDMap[tenant] || null;
}
function getexperimentUserPrefix(tenant: string) {
  return tenantexperimentUserPrefixMap[tenant] || null;
}

function createHoustonConfig({ tenant, environment, username, password }: TCreateHoustonConfigProps): {
  houstonClientConfig: THoustonClientConfig;
  houstonServerConfig: THoustonServerConfig;
} {
  const commonConfigOptions: THoustonConfig = {
    applicationId: getApplicationId(tenant),
    tenant: getTenantName(tenant),
    providerId: getproviderId(tenant),
    environment: environment || 'pre',
  };

  const houstonClientConfig: THoustonClientConfig = { ...commonConfigOptions };

  const houstonServerConfig: THoustonServerConfig = {
    ...commonConfigOptions,
    eventDispatcherOptions: {
      experimentUserPrefix: getexperimentUserPrefix(tenant),
      credentials: {
        username,
        password,
      },
    },
  };

  return { houstonClientConfig, houstonServerConfig };
}

export default createHoustonConfig;
