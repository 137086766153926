import { seoUrlPart } from '@ecg-marktplaats/aurora-js-formatters';
import type { CategoryObject } from '../types';

/**
 *
 * Often we need to send SEO friendly category name to our third party partners
 * because this is now using the dataLayer category name, it is not consistent across multiple BFF's
 * This method aims to fix that issue, it will always format the category name if available
 * and add it to the existing datalayer variable, so it can be used

 */
const getSeoFriendlyCategoryName = (l1: CategoryObject): CategoryObject => {
  let l1Ascii = l1.n || '';
  try {
    l1Ascii = seoUrlPart(l1.n || '');
  } catch (e) {
    // do nothing
  }

  return {
    id: l1.id,
    n: l1Ascii,
  };
};

export default getSeoFriendlyCategoryName;
