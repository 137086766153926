import type { getOptimizelyClient as TGetClient } from '../client/browser';
import type { UserAttributes } from '@optimizely/optimizely-sdk';

const isFeatureEnabled =
  (getOptimizelyClient: typeof TGetClient) =>
  ({
    featureKey,
    experimentUser,
    attributes,
    tenantKey,
  }: {
    featureKey: string;
    experimentUser: string | number | null;
    attributes?: UserAttributes;
    tenantKey: string;
  }): boolean => {
    const optimizelyClient = getOptimizelyClient(tenantKey);
    if (!optimizelyClient || !tenantKey || !experimentUser) {
      return false;
    }

    return optimizelyClient.isFeatureEnabled(featureKey, `${experimentUser}`, attributes);
  };

export default isFeatureEnabled;
